// YOUTUBE
/*var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;
var video_id = (lang == 'es' ? "MkZezcMCmTg" : "u-GmOjR3Gb0")
function onYouTubeIframeAPIReady() {
    player = new YT.Player('player', {
        height: '1280',
        width: '720',
        videoId: video_id,
        playerVars: {
            'autoplay': 0,
            'controls': 2,
            'rel': 0,
            'color': 'white',
        },
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}

function onPlayerReady(event) {
    event.target.setPlaybackQuality('hd720');
    event.target.playVideo();
}

function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PAUSED) {
        console.log("Paused");
    }

    if (event.data == YT.PlayerState.PLAYING) {
        console.log("Playing");
    }

    if (event.data == YT.PlayerState.ENDED) {
        $(".btn.btn-effect").removeClass("fade");
       // end();
    }
}*/

$("#video").bind('pause', function(){
    console.log("Pausado");
});
$("#video").bind('playing', function(){
    console.log("Reproduciendo");
});
$("#video").bind('ended', function(){
    console.log("Termino");
    $(".btn.btn-effect").removeClass("fade");
});

