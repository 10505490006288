var already_submitted = false;

jQuery(document).ready(function() {
    $("#login").validate({
        ignore: [],
        focusInvalid: false,
        onfocusout: false,
        onkeyup: false,
        invalidHandler: function(form, validator) {
            if (!validator.numberOfInvalids())
                return;
            var elem = validator.errorList[0].element.id;

            $(window).scrollTo("#" + elem, 200, {
                easing: 'easeOutQuart',
                offset: -160,
                queue: false,
                onAfter: function() {
                    $("#" + elem).focus();
                }
            });
        },
        onfocusout: false,
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true,
                minlength: 6

            },
        },
        messages: {

        },
        showErrors: function(errorMap, errorList) {
            blnValidated_at_least_once = true;
            $.each(this.successList, function(index, value) {

                var _popover = $(value).popover({
                    trigger: 'manual',
                    placement: 'top',
                    content: value.message,
                    html: true,
                    container: '.form-group',
                    template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                });

                $(value).popover('hide');
            });
            var x_count = 0;
            $.each(errorList, function(index, value) {

                if (x_count > 0) {

                } else {
                    x_count++;

                    if (value.element.id.indexOf("cha_txt") > -1) {

                        var _popover = $(value.element).popover({
                            trigger: 'manual',
                            placement: 'bottom',
                            content: '',
                            container: 'body',
                            html: true,
                            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                        });

                        $(value.element).attr('data-content', value.message);

                        _popover.popover('show');
                        setTimeout(function() {
                            $(value.element).popover('hide');
                        }, 2000);

                    } else {

                        var _popover = $(value.element).popover({
                            trigger: 'manual',
                            placement: 'top',
                            content: '',
                            container: 'body',
                            html: true,
                            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                        });

                        $(value.element).attr('data-content', value.message);

                        _popover.popover('show');

                        setTimeout(function() {
                            $(value.element).popover('hide');
                        }, 2000);

                    }
                }
            });
        },
        submitHandler: function(form) {
            if (!window.already_submitted) {
                window.already_submitted = true;
                //$(".lltawfegrdewc").val($.cookie('SESS_llsudjwioweew'));
                form.method = "POST";
                form.action = lang_url + "/login/proc";
                form.submit();
            }
        }
    });


    $.validator.addMethod("recaptcha", function(value, element, param) {
        return (grecaptcha.getResponse() != '');
    }, "Debe validar el reCAPTCHA para continuar.");

    if ($("html").hasClass("ie8")) {} else {
        if (!window.addEventListener) {
            window.attachEvent("onorientationchange", doOrientationChange);
        } else {
            window.addEventListener("onorientationchange", doOrientationChange);
        }

        // window.xhandleResize();
    }
});

var xhandleResize = function() {
    $(window).one("resize", function() {
        if (!Modernizr.touch) {
            $('input[type=text],input[type=password],input[type=checkbox],select').each(function() {
                if ($(this).attr("id") == "confirm") {
                    var offset = $(this).offset();
                    var ol = offset.left - 59;
                    var ot = offset.top + 13;
                    $(this).parent('.checkbox').find('.tooltip').css({
                        'left': ol + 'px',
                        'top': ot + 'px'
                    });
                } else {
                    $(this).blur();
                }
            });
        }
        setTimeout("xhandleResize()", 200);
    });
}
var x_reposition = function() {
    $('input[type=text],input[type=password],input[type=checkbox],select,textarea').find(":visible").each(function() {
        $('input[type=text],input[type=password],input[type=checkbox],select,textarea').find(":visible").each(function() {
            if ($(this).attr("id") == "confirm") {
                var offset = $(this).offset();
                var ol = offset.left - 59;
                var ot = offset.top + 13;
                $(this).parent('.checkbox').find('.tooltip').css({
                    'left': ol + 'px',
                    'top': ot + 'px'
                });
            } else {
                $(this).blur();
            }
        });
    });
};

function doOrientationChange() {
    x_reposition();
}
;
