//CUESTIONARIO
$(function() {
    $('fieldset input.answer-option').click(function(event) {
        $('.btn.siguiente').removeAttr('disabled').removeClass('disabled');
    });
    
    $('#quiz-form').submit(function () {
        $('.btn.siguiente').attr('disabled', 'disabled').addClass('disabled');
        return true;
    });
});