$(function() {
    new WOW().init();
    if (matchMedia) {
        var mq = window.matchMedia("(min-width: 992px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
    }

    $('.md-select').material_select();
    //HERO
    function WidthChange(mq) {
        if (mq.matches) {
            $(".portada").each(function() {
                var attr = $(this).attr('data-image-src');
                if (typeof attr !== typeof undefined && attr !== false) {
                    $(this).css('background-image', 'url(' + attr + ')');
                }
            });
        } else {
            $(".portada").each(function() {
                var attr = $(this).attr('data-image-src-sm');
                if (typeof attr !== typeof undefined && attr !== false) {
                    $(this).css('background-image', 'url(' + attr + ')');
                }
            });
        }
    }





    $('#modal-country').modal('show');



    /*******************************************************************************
    SVG TO HTML
    *******************************************************************************/

    jQuery('img.svg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            var $svg = jQuery(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });



/*******************************************************************************
Animaciones
*******************************************************************************/

    var tl = new TimelineLite(),
        anim1 = $(".anim-01"),
        anim2 = $(".anim-02"),
        anim3 = $(".anim-03");

    tl.to(anim1, 1.2, {y:'0', opacity:1})
      .to(anim2, 1.2, {y:'0', opacity:1}, '-=0.75')
      .to(anim3, 1.2, {y:'0', opacity:1}, '-=0.75');

});
